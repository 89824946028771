<template>
  <el-card>
    <el-form
      v-if="data[type]"
    >
      <div>
        <el-row :gutter="20">
          <el-col :span="24">
          <div class="panel-item">
            <el-row class="mb30">
              <el-form-item label="Даваа"></el-form-item>
              <el-col :span="24" :class="checkDiff('mon', 'startTime')">
                <el-time-picker
                  disabled
                    style="width: 100%"
                    size="mini"
                    v-model="data[type].timetable.mon.startTime"
                    value-format="HH:mm"
                  >
                </el-time-picker>
              </el-col>
              <el-col :span="24" :class="checkDiff('mon', 'endTime')">
                <el-time-picker
                  disabled
                  style="width: 100%"
                  size="mini"
                  v-model="data[type].timetable.mon.endTime"
                  value-format="HH:mm"
                >
                </el-time-picker>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="mb30">
              <el-form-item label="Мягмар"></el-form-item>
              <el-col :span="24" :class="checkDiff('tue', 'startTime')">
                <el-time-picker
                  disabled
                    style="width: 100%"
                    size="mini"
                    v-model="data[type].timetable.tue.startTime"
                    value-format="HH:mm"
                  >
                </el-time-picker>
              </el-col>
              <el-col :span="24" :class="checkDiff('tue', 'endTime')">
                <el-time-picker
                  disabled
                  style="width: 100%"
                  size="mini"
                  v-model="data[type].timetable.tue.endTime"
                  end-placeholder="Хаах цаг"
                  value-format="HH:mm"
                >
                </el-time-picker>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="mb30">
              <el-form-item label="Лхагва"></el-form-item>
              <el-col :span="24" :class="checkDiff('wed', 'startTime')">
                <el-time-picker
                  disabled
                    style="width: 100%"
                    size="mini"
                    v-model="data[type].timetable.wed.startTime"
                    value-format="HH:mm"
                  >
                </el-time-picker>
              </el-col>
              <el-col :span="24" :class="checkDiff('wed', 'endTime')">
                <el-time-picker
                  disabled
                  style="width: 100%"
                  size="mini"
                  v-model="data[type].timetable.wed.endTime"
                  end-placeholder="Хаах цаг"
                  value-format="HH:mm"
                >
                </el-time-picker>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="mb30">
              <el-form-item label="Пүрэв"></el-form-item>
              <el-col :span="24" :class="checkDiff('thu', 'startTime')">
                <el-time-picker
                  disabled
                    style="width: 100%"
                    size="mini"
                    v-model="data[type].timetable.thu.startTime"
                    value-format="HH:mm"
                  >
                </el-time-picker>
              </el-col>
              <el-col :span="24" :class="checkDiff('thu', 'endTime')">
                <el-time-picker
                  disabled
                  style="width: 100%"
                  size="mini"
                  v-model="data[type].timetable.thu.endTime"
                  end-placeholder="Хаах цаг"
                  value-format="HH:mm"
                >
                </el-time-picker>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="mb30">
              <el-form-item label="Баасан"></el-form-item>
              <el-col :span="24" :class="checkDiff('fri', 'startTime')">
                <el-time-picker
                  disabled
                    style="width: 100%"
                    size="mini"
                    v-model="data[type].timetable.fri.startTime"
                    value-format="HH:mm"
                  >
                </el-time-picker>
              </el-col>
              <el-col :span="24" :class="checkDiff('fri', 'endTime')">
                <el-time-picker
                  disabled
                  style="width: 100%"
                  size="mini"
                  v-model="data[type].timetable.fri.endTime"
                  end-placeholder="Хаах цаг"
                  value-format="HH:mm"
                >
                </el-time-picker>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="mb30">
              <el-form-item label="Бямба"></el-form-item>
              <el-col :span="24" :class="checkDiff('sat', 'startTime')">
                <el-time-picker
                  disabled
                    style="width: 100%"
                    size="mini"
                    v-model="data[type].timetable.sat.startTime"
                    value-format="HH:mm"
                  >
                </el-time-picker>
              </el-col>
              <el-col :span="24" :class="checkDiff('sat', 'endTime')">
                <el-time-picker
                  disabled
                  style="width: 100%"
                  size="mini"
                  v-model="data[type].timetable.sat.endTime"
                  end-placeholder="Хаах цаг"
                  value-format="HH:mm"
                >
                </el-time-picker>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="mb30">
              <el-form-item label="Ням"></el-form-item>
              <el-col :span="24" :class="checkDiff('sun', 'startTime')">
                <el-time-picker
                  disabled
                    style="width: 100%"
                    size="mini"
                    v-model="data[type].timetable.sun.startTime"
                    value-format="HH:mm"
                  >
                </el-time-picker>
              </el-col>
              <el-col :span="24" :class="checkDiff('sun', 'endTime')">
                <el-time-picker
                  disabled
                  style="width: 100%"
                  size="mini"
                  v-model="data[type].timetable.sun.endTime"
                  end-placeholder="Хаах цаг"
                  value-format="HH:mm"
                >
                </el-time-picker>
              </el-col>
            </el-row>
          </div>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </el-card>
</template>

<script>
export default {
  name: 'DetailComponent',
  props: {
    data: {
      type: Object,
      default: () => null
    },
    type: {
      type: String,
      default: () => null
    }
  },
  methods: {
    checkDiff (field, attr) {
      if (
        JSON.stringify(this.data.old.timetable[field][attr]) ===
        JSON.stringify(this.data.new.timetable[field][attr])
      ) {
        return ''
      } else {
        return 'diffHistory'
      }
    }
  }
}
</script>

<style>
.aa {
  color: green;
}
.diffHistory {
  /* background-color: #E4EBE4;  */
  border-style: dotted;
}
</style>
