<template>
  <div>
  <el-drawer
      :title="'Цагийн түүх '"
      v-if="timeTableLogs"
      :visible="isShow"
      size="90%"
      style="align-items: center; height: 100%;"
      @close="mCloseDialog()"
      @open="mOpenDialog()"
    >
      <el-table :data="timeTableLogs">
        <el-table-column type="index" label="№" width="50"></el-table-column>
        <el-table-column prop="created_at" label="Огноо"></el-table-column>
        <el-table-column
          prop="desc"
          label="Тайлбар"
        ></el-table-column>
        <el-table-column
          prop="user"
          label="Өөрчилсөн"
        ></el-table-column>
        <el-table-column label="Дэлгэрэнгүй">
          <template slot-scope="prop">
            <el-button
              type="primary"
              size="mini"
              @click="getHistoryDetail(prop.row)"
              >Дэлгэрэнгүй</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="text-right mt10"
        background
        layout="total, prev, pager, next"
        :total="total"
        :page-size="pageSize"
        @current-change="scheduleHistories"
      >
      </el-pagination>
    </el-drawer>
    <DetailDialog
      :isShow="isDetailShow"
      :historyId="historyId"
      :outletId="outletId"
      :closeFunction="mCloseDetailDialog"
    />
  </div>
</template>
<script>
import mAxios from '../../../../../helper/axiosInstance'
import DetailDialog from './TimetableDetailDialog.vue'
export default {
  name: 'ScheduleHistoryList',
  components: {
    DetailDialog
  },
  props: {
    isShow: {
      type: Boolean,
      default: () => false
    },
    outletId: {
      type: String,
      default: () => null
    },
    closeFunction: {
      type: Function,
      default: () => null
    }
  },
  data () {
    return {
      total: 0,
      page: 1,
      pageSize: 10,
      isDetailShow: false,
      historyId: null,
      timeTableLogs: []
    }
  },
  methods: {
    mCloseDialog () {
      this.isDetailShow = false
      this.closeFunction()
    },
    mOpenDialog () {
      this.scheduleHistories(1)
    },
    scheduleHistories (page) {
      this.timeTableLogs = []
      mAxios
        .post('outlet/scheduleHistory', {
          outlet_id: this.outletId,
          page: page
        })
        .then((response) => {
          if (response.data.status === 'success') {
            this.timeTableLogs = response.data.data.hits
            this.page = response.data.data.current_page
            this.total = response.data.data.total
            this.pageSize = response.data.data.page_size
          } else {
            this.$notify({
              title: 'Алдаа',
              message: response.data.message,
              type: 'error',
              position: 'bottom-left'
            })
          }
          this.loading = false
        })
    },
    mCloseDetailDialog () {
      this.isDetailShow = false
    },
    getHistoryDetail (data) {
      this.historyId = data._id
      this.isDetailShow = true
    }
  }
}
</script>
