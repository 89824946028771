<template>
  <div class="schedule">
    <header class="page-header">
      <el-row type="flex" align="middle" class="headerLine ml10">
        <title-item title="Цагийн хуваарь" />
        <button class="btn" type="button" @click="showScheduleHistoryDialog()">
          <i class="el-icon-document"></i>
          <span>Түүх</span>
        </button>
      </el-row>
    </header>
    <el-select class="select" v-model="value" placeholder="Select" @change="selectVal">
      <el-option v-for="(item, index) in $root.chosenOutlets" :key="index" :label="item.full_name_eng"
        :value="item._id" />
    </el-select>
    <div class="dashLine mt20 mb20"></div>
    <div class="panel">
      <el-row type="flex" v-if="$root.chosenOutlets.length > 0 && page_time_table">
        <el-col :span="6" class="border-right scheduleList">
          <ul class="list-style-none mt0 mb0 p0 menu-list">
            <li v-for="(outlet, index) in $root.chosenOutlets" :key="index"
              :class="outlet._id == currentOutlet._id ? 'active' : ''" @click="setCurrentOutlet(outlet)">
              {{ outlet.full_name_eng }}
            </li>
          </ul>
        </el-col>
        <el-col>
          <el-form label-position="top" size="medium" v-model="page_time_table" v-if="page_time_table"
            v-loading="loading">
            <el-row class="mt20">
              <el-col :span="22" :offset="1">
                <el-form-item label="Даваа">
                  <el-row :gutter="20">
                    <el-time-select class="mobileTimePicker" size="medium" v-model="page_time_table.mon.startTime" @input="setTime(page_time_table.mon.startTime, 'start')"
                        value-format="HH:mm"
                       :picker-options="pickerOption">
                    </el-time-select>
                    <el-popover
                      placement="top-start"
                      width="200"
                      trigger="hover"
                      content="Харилцагч та сүүлийн захиалга орох цагаа тохируулаарай.">
                        <el-time-select slot="reference" class="mobileTimePicker" size="medium" v-model="page_time_table.mon.endTime"
                          value-format="HH:mm" @input="setTime(page_time_table.mon.endTime, 'end')"
                            :picker-options="pickerOption">
                        </el-time-select>
                      </el-popover>
                  </el-row>
                </el-form-item>
                <el-form-item label="Мягмар">
                  <el-row :gutter="20">
                    <el-time-select class="mobileTimePicker" size="medium" v-model="page_time_table.tue.startTime"
                      value-format="HH:mm"
                      :picker-options="pickerOption">
                    </el-time-select>
                    <el-time-select class="mobileTimePicker" size="medium" v-model="page_time_table.tue.endTime"
                      value-format="HH:mm"
                      :picker-options="pickerOption">
                    </el-time-select>
                  </el-row>
                </el-form-item>
                <el-form-item label="Лхагва">
                  <el-row :gutter="20">
                    <el-time-select class="mobileTimePicker" size="medium" v-model="page_time_table.wed.startTime"
                      value-format="HH:mm"
                      :picker-options="pickerOption">
                    </el-time-select>
                    <el-time-select class="mobileTimePicker" size="medium" v-model="page_time_table.wed.endTime"
                      value-format="HH:mm"
                      :picker-options="pickerOption">
                    </el-time-select>
                  </el-row>
                </el-form-item>
                <el-form-item label="Пүрэв">
                  <el-row :gutter="20">
                    <el-time-select class="mobileTimePicker" size="medium" v-model="page_time_table.thu.startTime"
                      value-format="HH:mm"
                      :picker-options="pickerOption">
                    </el-time-select>
                    <el-time-select class="mobileTimePicker" size="medium" v-model="page_time_table.thu.endTime"
                      value-format="HH:mm"
                      :picker-options="pickerOption">
                    </el-time-select>
                  </el-row>
                </el-form-item>
                <el-form-item label="Баасан">
                  <el-row :gutter="20">
                    <el-time-select class="mobileTimePicker" size="medium" v-model="page_time_table.fri.startTime"
                      value-format="HH:mm"
                      :picker-options="pickerOption">
                    </el-time-select>
                    <el-time-select class="mobileTimePicker" size="medium" v-model="page_time_table.fri.endTime"
                      value-format="HH:mm"
                      :picker-options="pickerOption">
                    </el-time-select>
                  </el-row>
                </el-form-item>
                <el-form-item label="Бямба">
                  <el-row :gutter="20">
                    <el-time-select class="mobileTimePicker" size="medium" v-model="page_time_table.sat.startTime"
                      value-format="HH:mm"
                      :picker-options="pickerOption">
                    </el-time-select>
                    <el-time-select class="mobileTimePicker" size="medium" v-model="page_time_table.sat.endTime"
                      value-format="HH:mm"
                      :picker-options="pickerOption">
                    </el-time-select>
                  </el-row>
                </el-form-item>
                <el-form-item label="Ням">
                  <el-row :gutter="20">
                    <el-time-select class="mobileTimePicker" size="medium" v-model="page_time_table.sun.startTime"
                      value-format="HH:mm"
                      :picker-options="pickerOption">
                    </el-time-select>
                    <el-time-select class="mobileTimePicker" size="medium" v-model="page_time_table.sun.endTime"
                      value-format="HH:mm"
                      :picker-options="pickerOption">
                    </el-time-select>
                  </el-row>
                </el-form-item>
              </el-col>
              <div class="text-right history">
                <el-button type="success" size="small" @click="showScheduleHistoryDialog()">Түүх харах</el-button>
              </div>
            </el-row>
            <div class="text-right pr20 saveBtn">
              <el-button type="default" @click="resetForm()">Буцах</el-button>
              <el-button type="success" @click="sendData(page_time_table)">Хадгалах</el-button>
            </div>
            <div style=" display: flex; justify-content: center;">
              <button type="button" class="mobileSaveBtn" @click="sendData(page_time_table)">
                <span style=" font-size: 18px;">
                  Хадгалах
                </span>
              </button>
            </div>
          </el-form>
          <!-- <el-form
            v-model="page_time_table"
            v-if="page_time_table"
            v-loading="loading"
          >
            <div class="panel-item">
              <el-row>
                <el-col :span="14" :offset="4">
                  <el-form-item label="Даваа">
                    <el-row :gutter="20">
                      <el-time-picker
                        style="width: 100%"
                        size="medium"
                        is-range
                        v-model="page_time_table.mon"
                        range-separator="-"
                        start-placeholder="Нээх цаг"
                        end-placeholder="Хаах цаг"
                        value-format="HH:mm"
                      >
                      </el-time-picker>
                    </el-row>
                  </el-form-item>
                  <el-form-item label="Мягмар">
                    <el-row :gutter="20">
                      <el-time-picker
                        style="width: 100%"
                        size="medium"
                        is-range
                        v-model="page_time_table.tue"
                        range-separator="-"
                        start-placeholder="Нээх цаг"
                        end-placeholder="Хаах цаг"
                        value-format="HH:mm"
                      >
                      </el-time-picker>
                    </el-row>
                  </el-form-item>
                  <el-form-item label="Лхагва">
                    <el-row :gutter="20">
                      <el-time-picker
                        style="width: 100%"
                        size="medium"
                        is-range
                        v-model="page_time_table.wed"
                        range-separator="-"
                        start-placeholder="Нээх цаг"
                        end-placeholder="Хаах цаг"
                        value-format="HH:mm"
                      >
                      </el-time-picker>
                    </el-row>
                  </el-form-item>
                  <el-form-item label="Пүрэв">
                    <el-row :gutter="20">
                      <el-time-picker
                        style="width: 100%"
                        size="medium"
                        is-range
                        v-model="page_time_table.thu"
                        range-separator="-"
                        start-placeholder="Нээх цаг"
                        end-placeholder="Хаах цаг"
                        value-format="HH:mm"
                      >
                      </el-time-picker>
                    </el-row>
                  </el-form-item>
                  <el-form-item label="Баасан">
                    <el-row :gutter="20">
                      <el-time-picker
                        style="width: 100%"
                        size="medium"
                        is-range
                        v-model="page_time_table.fri"
                        range-separator="-"
                        start-placeholder="Нээх цаг"
                        end-placeholder="Хаах цаг"
                        value-format="HH:mm"
                      >
                      </el-time-picker>
                    </el-row>
                  </el-form-item>
                  <el-form-item label="Бямба">
                    <el-row :gutter="20">
                      <el-time-picker
                        style="width: 100%"
                        size="medium"
                        is-range
                        v-model="page_time_table.sat"
                        range-separator="-"
                        start-placeholder="Нээх цаг"
                        end-placeholder="Хаах цаг"
                        value-format="HH:mm"
                      >
                      </el-time-picker>
                    </el-row>
                  </el-form-item>
                  <el-form-item label="Ням">
                    <el-row :gutter="20">
                      <el-time-picker
                        style="width: 100%"
                        size="medium"
                        is-range
                        v-model="page_time_table.sun"
                        range-separator="-"
                        start-placeholder="Нээх цаг"
                        end-placeholder="Хаах цаг"
                        value-format="HH:mm"
                      >
                      </el-time-picker>
                    </el-row>
                  </el-form-item>
                </el-col>
                <el-col :span="5" class="text-right">
                  <el-button
                    type="success"
                    size="small"
                    @click="showScheduleHistoryDialog()"
                    >Түүх харах</el-button
                  >
                </el-col>
                <el-col :span="13" class="text-right pr20">
                  <el-button type="default" @click="resetForm()"
                    >Буцах</el-button
                  >
                  <el-button type="success" @click="sendData(page_time_table)"
                    >Хадгалах</el-button
                  >
                </el-col>
              </el-row>
            </div>
          </el-form> -->
          <p v-else class="text-center pt50 pb50 text-secondary">
            Харилцагчийн мэдээлэл олдсонгүй
          </p>
        </el-col>
      </el-row>
    </div>
    <history-dialog :isShow="isDialogShow" :outletId="currentOutlet ? currentOutlet._id : null"
      :closeFunction="hideScheduleHistoryDialog"></history-dialog>
  </div>
</template>
<script>
import mAxios from '../../../helper/axiosInstance'
import HistoryDialog from './components/history/index.vue'
import TitleItem from '../../../components/items/titleItem.vue'
export default {
  name: 'TimeTable',
  components: {
    HistoryDialog,
    TitleItem
  },
  data () {
    return {
      pickerOption: {
        start: '00:05',
        step: '00:05',
        end: '23:55'
      },
      value: this.$root.chosenOutlets[0].full_name_eng,
      loading: false,
      page_time_table: {
        mon: {
          startTime: '',
          endTime: ''
        },
        tue: {
          startTime: '',
          endTime: ''
        },
        wed: {
          startTime: '',
          endTime: ''
        },
        thu: {
          startTime: '',
          endTime: ''
        },
        fri: {
          startTime: '',
          endTime: ''
        },
        sat: {
          startTime: '',
          endTime: ''
        },
        sun: {
          startTime: '',
          endTime: ''
        }
      },
      currentOutlet: null,
      isDialogShow: false,
      outletId: null
    }
  },
  async created () {
    if (this.$root.chosenOutlets.length > 0) {
      this.currentOutlet = this.$root.chosenOutlets[0]
      this.setCurrentOutlet(this.currentOutlet)
    }
  },
  methods: {
    setTime (time, type) {
      if (type === 'start') {
        this.page_time_table.tue.startTime = time
        this.page_time_table.wed.startTime = time
        this.page_time_table.thu.startTime = time
        this.page_time_table.fri.startTime = time
        this.page_time_table.sat.startTime = time
        this.page_time_table.sun.startTime = time
      } else {
        this.page_time_table.tue.endTime = time
        this.page_time_table.wed.endTime = time
        this.page_time_table.thu.endTime = time
        this.page_time_table.fri.endTime = time
        this.page_time_table.sat.endTime = time
        this.page_time_table.sun.endTime = time
      }
    },
    showScheduleHistoryDialog () {
      this.isDialogShow = true
    },
    hideScheduleHistoryDialog () {
      this.isDialogShow = false
    },
    resetForm () {
      this.currentOutlet = null
    },
    setCurrentOutlet (outlet) {
      this.loading = true
      this.currentOutlet = outlet
      mAxios
        .get(`outlet/${this.currentOutlet._id}/getOutletTimetableById`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.page_time_table.mon.startTime =
              response.data.data.mon.startTime
            this.page_time_table.mon.endTime = response.data.data.mon.endTime
            this.page_time_table.tue.startTime =
              response.data.data.tue.startTime
            this.page_time_table.tue.endTime = response.data.data.tue.endTime
            this.page_time_table.wed.startTime =
              response.data.data.wed.startTime
            this.page_time_table.wed.endTime = response.data.data.wed.endTime
            this.page_time_table.thu.startTime =
              response.data.data.thu.startTime
            this.page_time_table.thu.endTime = response.data.data.thu.endTime
            this.page_time_table.fri.startTime =
              response.data.data.fri.startTime
            this.page_time_table.fri.endTime = response.data.data.fri.endTime
            this.page_time_table.sat.startTime =
              response.data.data.sat.startTime
            this.page_time_table.sat.endTime = response.data.data.sat.endTime
            this.page_time_table.sun.startTime =
              response.data.data.sun.startTime
            this.page_time_table.sun.endTime = response.data.data.sun.endTime
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectVal (item) {
      this.loading = true
      this.currentOutlet = this.$root.chosenOutlets.find(
        (el) => el._id === item
      )
      mAxios
        .get(`outlet/${this.currentOutlet._id}/getOutletTimetableById`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.page_time_table.mon.startTime =
              response.data.data.mon.startTime
            this.page_time_table.mon.endTime = response.data.data.mon.endTime
            this.page_time_table.tue.startTime =
              response.data.data.tue.startTime
            this.page_time_table.tue.endTime = response.data.data.tue.endTime
            this.page_time_table.wed.startTime =
              response.data.data.wed.startTime
            this.page_time_table.wed.endTime = response.data.data.wed.endTime
            this.page_time_table.thu.startTime =
              response.data.data.thu.startTime
            this.page_time_table.thu.endTime = response.data.data.thu.endTime
            this.page_time_table.fri.startTime =
              response.data.data.fri.startTime
            this.page_time_table.fri.endTime = response.data.data.fri.endTime
            this.page_time_table.sat.startTime =
              response.data.data.sat.startTime
            this.page_time_table.sat.endTime = response.data.data.sat.endTime
            this.page_time_table.sun.startTime =
              response.data.data.sun.startTime
            this.page_time_table.sun.endTime = response.data.data.sun.endTime
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    sendData () {
      this.loading = true
      const payload = {
        outlet_id: this.currentOutlet._id,
        time_table: this.page_time_table
      }
      mAxios
        .post(`outlet/${this.currentOutlet._id}/updateTimeSchedule`, payload)
        .then((response) => {
          if (response.data.status === 'success') {
            this.$notify({
              title: 'Амжилттай',
              message: response.data.data.message,
              type: 'success'
            })
          } else {
            this.$notify({
              title: 'Алдаа',
              message: response.data.message,
              type: 'error'
            })
          }
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
